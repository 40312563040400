import { NavigateFunction } from 'react-router-dom';
import { AppState, AppThunkDispatch } from '..';
import { showNotification } from '../../utils/util';
import CustomerAssetWorkflow from '../../services/customer-asset-workflow';
import { RequestTypes, ownershipRequestsActionNames } from './types';
import { UpdateOwnershipRequest } from '../../entities/customerAssetWorkflow-v1/updateOwnershipRequest';
import { OwnershipRequestCreateRequest } from '../../entities/customerAssetWorkflow-v1/ownershipRequestCreateRequest';
import i18n from 'i18next';
import TEXT from '../../globals/translation-map';
import { default as Axios } from 'axios';
import { CancelTokenSource } from 'axios/index';

let ajaxRequest: CancelTokenSource | null = null;

export const fetchOwnershipRequests = (cursor?: string) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    ajaxRequest = Axios.CancelToken.source();
    const {
      login: { authInfo, environmentVariables },
      ownershipRequests: { cursors, ownershipRequests, requestType, received, sent },
    } = getState();
    const service = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );
    const receivedSentFromStore =
      requestType.toLocaleUpperCase() === RequestTypes.RECEIVED
        ? structuredClone(received)
        : structuredClone(sent);
    const requestBody =
      receivedSentFromStore.filters?.status?.length > 0
        ? {
            logicalExpression: '$0',
            filters: [
              {
                propertyName: 'ownershipRequestStatusCode',
                type: 'stringEquals',
                values: receivedSentFromStore.filters.status.map((status: string) =>
                  status.toLocaleUpperCase(),
                ),
              },
            ],
          }
        : {};
    const request = {
      limit: 100,
      body: requestBody,
      ownershipRequestType: requestType,
      sortBy:
        receivedSentFromStore.sortBy?.toString() === ''
          ? 'ownershipRequestStatusLabel'
          : receivedSentFromStore.sortBy?.toString(),
      orderBy:
        receivedSentFromStore.orderBy?.toString() === ''
          ? 'DESC'
          : receivedSentFromStore.orderBy?.toString(),
      cursor: cursor,
    };
    const requestCount = {
      body: requestBody,
      ownershipRequestType: requestType,
    };

    try {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS,
        payload: true,
      });
      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS,
        payload: [],
      });
      const [newWorkflow, newCount] = await Promise.all([
        service.searchOwnershipRequests(request, ajaxRequest ? ajaxRequest.token : undefined),
        service.countOwnershipRequests(requestCount, ajaxRequest ? ajaxRequest.token : undefined),
      ]);
      const data: any = newWorkflow?.data;
      if (
        data?.responseMetadata?.nextCursor &&
        !cursors.includes(data.responseMetadata.nextCursor)
      ) {
        dispatch({
          type: ownershipRequestsActionNames.SET_CURSORS_FOR_OWNERSHIP_REQUEST,
          payload: [...cursors, data?.responseMetadata?.nextCursor],
        });
      }
      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS,
        payload:
          request.cursor && ownershipRequests
            ? [...(newWorkflow as any)?.data?.ownershipRequests]
            : (newWorkflow as any)?.data?.ownershipRequests || [],
      });

      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_COUNT,
        payload: (newCount as any)?.data?.count || 0,
      });
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_RETRIEVE_FAILED),
        { persist: true },
      );
    } finally {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS,
        payload: false,
      });
    }
  };
};
export const getOwnershipRequest = (ownershipRequestIdentifier: string) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    const service = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );
    dispatch({
      type: ownershipRequestsActionNames.SET_SELECTED_OWNERSHIP_REQUEST,
      payload: undefined,
    });
    try {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_GET_OWNERSHIP_REQUEST,
        payload: true,
      });

      const response = await service.getOwnershipRequest(ownershipRequestIdentifier);
      if (response.status === 200) {
        dispatch({
          type: ownershipRequestsActionNames.SET_SELECTED_OWNERSHIP_REQUEST,
          payload: response?.data,
        });
      } else {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_DETAILS_FAILED),
          {
            persist: true,
          },
        );
      }
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_FAILED),
        {
          persist: true,
        },
      );
    } finally {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_GET_OWNERSHIP_REQUEST,
        payload: false,
      });
    }
  };
};

export const deleteOwnershipRequest = (
  ownershipRequestIdentifier: string,
  setDeleteOwnershipRequestSuccessful?: Function,
) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    const service = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );
    try {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST,
        payload: true,
      });

      const response = await service.deleteOwnershipRequest(ownershipRequestIdentifier);
      if (response.status === 204) {
        showNotification(
          dispatch,
          'success',
          i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.NOTIFICATIONS.DELETE_ASSETS_OWNERSHIP),
          undefined,
          'delete-request',
        );
        dispatch({
          type: ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST,
          payload: false,
        });
        setDeleteOwnershipRequestSuccessful !== undefined &&
          setDeleteOwnershipRequestSuccessful(true);
      } else {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.DELETE_ASSETS_OWNERSHIP_FAILED),
          undefined,
          'delete-request',
        );
        dispatch({
          type: ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST,
          payload: false,
        });
      }
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.DELETE_ASSETS_OWNERSHIP_FAILED),
        undefined,
        'delete-request',
      );
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST,
        payload: false,
      });
    }
  };
};

export const updateOwnershipRequest = (
  ownershipRequestIdentifier: string,
  requestBody: UpdateOwnershipRequest,
  navigate?: NavigateFunction,
  url?: string,
) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    const service = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );

    try {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST,
        payload: true,
      });

      const response = await service.updateOwnershipRequest(
        ownershipRequestIdentifier,
        requestBody,
      );
      if (response.status === 200) {
        showNotification(
          dispatch,
          'success',
          i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.NOTIFICATIONS.OWNERSHIP_UPDATE),
          {
            persist: true,
          },
        );
        if (navigate && url) {
          navigate(url);
        }
      } else {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_UPDATE_FAILED),
          {
            persist: true,
          },
        );
      }
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_FAILED),
        {
          persist: true,
        },
      );
    } finally {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST,
        payload: false,
      });
    }
  };
};

export const createOwnershipRequest = (
  request: OwnershipRequestCreateRequest,
  saveCallback: any,
  handleClose: any,
) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    const ownershipService = new CustomerAssetWorkflow(
      authInfo,
      environmentVariables.customerAssetWorkflowV1,
    );

    dispatch({
      type: ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS,
      payload: true,
    });
    try {
      await ownershipService.createOwnershipRequest(request);
      showNotification(
        dispatch,
        'success',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.NOTIFICATIONS.OWNERSHIP_CREATION),
      );
      if (saveCallback) saveCallback();
      handleClose();
    } catch {
      showNotification(
        dispatch,
        'error',
        i18n.t(TEXT.CUSTOMER_ASSET_WORKFLOW.ERRORS.OWNERSHIP_CREATION_FAILED),
        { persist: true },
      );
    } finally {
      dispatch({
        type: ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS,
        payload: false,
      });
    }
  };
};
